angular
  .module('app')
  .component('contact', {
    templateUrl: 'app/sections/contact.html',
    controller: ContactController,
    bindings: {
      myForm: '<'
    }
  });

/** @ngInject */
function ContactController($http, $state, colorService) {
  var vm = this;

  colorService.getBackgroundColor().then(function (data) {
    vm.backgroundColor = data;
  });

  vm.formData = {};
  vm.result = 'hidden';
  vm.resultMessage = '';
  vm.isFormValid = function () {
    return vm.contactForm.$invalid;
  };
  vm.submit = function (myForm, isValid) {
    vm.myForm = myForm;
    if (isValid) {
      $http({
        method: 'POST',
        url: 'contact-form.php',
        data: $.param(vm.formData.contact),  // param method from jQuery
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
      }).then(function (success) {
        vm.resultMessage = success.data.message;
        vm.result = 'bg-success';
        vm.formData = {};
        vm.myForm.$setPristine();
        $state.go('app.contact');
      }, function (error) {
        vm.resultMessage = error.data;
        vm.result = 'bg-danger';
        vm.formData = {};
        vm.myForm.$setPristine();
        $state.go('app.contact');
      });
    } else {
      vm.resultMessage = 'Failed :( Please fill out all the fields.';
      vm.result = 'bg-danger';
      $state.go('app.contact');
    }
  };

  $http
    .get('app/sections/contact.json', {cache: true})
    .then(function (response) {
      vm.content = response.data;
    });
}

// angular.module('app')
//   .directive('inputMask', function () {
//     return {
//       restrict: 'A',
//       link: function (scope, element, attrs) {
//         $(element).mask(scope.$eval(attrs.inputMask));
//       }
//     };
//   });
