angular
  .module('app')
  .component('dsInfo', {
    templateUrl: 'app/sections/info.html',
    controller: InfoController
  });

/** @ngInject */
function InfoController($http) {
  var vm = this;
  $http
    .get('app/sections/info.json', {cache: true})
    .then(function (response) {
      vm.info = response.data;
    });
}
