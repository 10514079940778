angular
  .module('app')
  .component('dsTitle', {
    templateUrl: 'app/title.html',
    controller: TitleController
  });

/** @ngInject */
function TitleController($http) {
  var vm = this;
  vm.myInterval = 5000;
  vm.noWrapSlides = false;
  vm.active = 0;
  $http
    .get('app/title.json', {cache: true})
    .then(function (response) {
      vm.slides = response.data;
    });
}
