angular.module('app')
  .directive('banner', function () {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
        var watchId = attrs.watchId;
        if (angular.isUndefined(watchId) || watchId === null) {
          return;
        }

        var previousPosition = document.querySelector('#' + watchId).getBoundingClientRect();
        // var timer;

        onFrame();

        function onFrame() {
          var elem = document.querySelector('#' + watchId);
          if (angular.isUndefined(elem) || elem === null) {
            return;
          }
          var currentPosition = elem.getBoundingClientRect();

          if (!angular.equals(previousPosition, currentPosition)) {
            resiszeNotifier(currentPosition);
          }

          previousPosition = currentPosition;
          requestAnimationFrame(onFrame);
        }

        function resiszeNotifier(currentPosition) {
          // if (!timer)
          //   setTimeout();
          element.find('img').css(
            {
              left: currentPosition.left + 'px',
              width: 'calc(100% - ' + currentPosition.left + 'px)',
              visibility: 'visible'
            }
          );
          // element.html(currentPosition.left);
          // #angular.element(document.querySelector('#' + watchId)).html(currentPosition.left);
        }
      }
    };
  });
