angular
  .module('app')
  .component('technology', {
    templateUrl: 'app/sections/technology.html',
    controller: TechnologyController
  });

/** @ngInject */
function TechnologyController($http, $state, colorService) {
  var vm = this;
  vm.state = $state;

  colorService.getBackgroundColor().then(function (data) {
    vm.backgroundColor = data;
  });

  $http
    .get('app/sections/technology.json', {cache: true})
    .then(function (response) {
      vm.content = response.data;
    });
}
