angular
  .module('app')
  .component('dsSection', {
    templateUrl: 'app/sections/section.html',
    bindings: {
      section: '<',
      colors: '<',
      state: '<'
    }
  });
