angular
  .module('app')
  .component('dsSections', {
    templateUrl: 'app/sections/sections.html',
    controller: SectionsController
  });

/** @ngInject */
function SectionsController($http, $state, colorService) {
  var vm = this;
  vm.state = $state;

  $http
    .get('app/sections/sections.json', {cache: true})
    .then(function (response) {
      response.data.forEach(function (currentValue, index, array) {
        array[index].link = $state.href(currentValue.key);
      });
      vm.sections = response.data;
      colorService.getColors().then(function (data) {
        var colors = data;
        vm.sections.forEach(function (currentValue) {
          currentValue.backgroundColor = vm.findObjectByKey(colors, 'key', currentValue.key).backgroundColor;
        });
      });
    });

  vm.findObjectByKey = function (array, key, value) {
    if (angular.isUndefined(array)) {
      return null;
    }
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  };
}
