angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      redirectTo: 'app.home',
      component: 'app'
    });

  $stateProvider
    .state('app.home', {
      url: '/',
      component: 'dsHome'
    });

  $stateProvider
    .state('app.about', {
      url: '/about',
      component: 'about'
    });

  $stateProvider
    .state('app.leadership', {
      url: '/leadership',
      component: 'leadership'
    });

  $stateProvider
    .state('app.technology', {
      url: '/technology',
      component: 'technology'
    });

  $stateProvider
    .state('app.technologies', {
      url: '/technologies',
      component: 'technologies'
    });

  $stateProvider
    .state('app.applications', {
      url: '/applications',
      component: 'applications'
    });

  $stateProvider
    .state('app.services', {
      url: '/services',
      component: 'services'
    });

  $stateProvider
    .state('app.resources', {
      url: '/resources',
      component: 'resources'
    });

  $stateProvider
    .state('app.publications', {
      url: '/publications',
      component: 'publications'
    });

  $stateProvider
    .state('app.testimonials', {
      url: '/testimonials',
      component: 'testimonials'
    });

  $stateProvider
    .state('app.collaborations', {
      url: '/collaborations',
      component: 'collaborations'
    });

  $stateProvider
    .state('app.contact', {
      url: '/contact',
      component: 'contact'
    });
}
