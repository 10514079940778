angular
  .module('app')
  .component('dsFooter', {
    templateUrl: 'app/footer.html',
    controller: FooterController
  });

/** @ngInject */
function FooterController($http) {
  var vm = this;
  $http
    .get('app/header.json', {cache: true})
    .then(function (response) {
      vm.menubar = response.data;
    });

  $http
    .get('app/footer.json', {cache: true})
    .then(function (response) {
      vm.footer = response.data;
    });
}
