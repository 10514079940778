angular
  .module('app')
  .factory('colorService', colorService
  );

/** @ngInject */
function colorService($state, $http) {
  return {
    getBackgroundColor: function () {
      return this.getColors()
        .then(function (response) {
          var colors = response;
          for (var i = 0; i < colors.length; i++) {
            if (colors[i].key === $state.current.name) {
              return colors[i].backgroundColor;
            }
          }
        });
    },
    getColors: function () {
      return $http.get('app/colors.json', {cache: true})
        .then(function (response) {
          var colors = response.data;
          return colors;
        });
    }
  };
}
