angular
  .module('app')
  .component('app', {
    templateUrl: 'app/main.html',
    controller: MainController
  });

/** @ngInject */
function MainController($state, $http, colorService) {
  var vm = this;
  vm.state = $state;

  colorService.getColors().then(function (data) {
    vm.colors = data;
  });

  vm.getBackgroundColor = function () {
    if (angular.isUndefined(vm.colors)) {
      return null;
    }
    for (var i = 0; i < vm.colors.length; i++) {
      if (vm.colors[i].key === $state.current.name) {
        return vm.colors[i].backgroundColor;
      }
    }
  };

  $http
    .get('app/sections/sections.json', {cache: true})
    .then(function (response) {
      response.data.forEach(function (currentValue, index, array) {
        array[index].link = $state.href(currentValue.key);
      });
      vm.sections = response.data;
    });

  vm.findObjectByKey = function (array, key, value) {
    if (angular.isUndefined(array)) {
      return null;
    }
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  };
}

// angular
//   .module('app')
//   .directive('linkWrap', function () {
//     return {
//       restrict: 'E',
//       compile: function () {
//         return {
//           pre: function ($scope, $element, $attributes) {
//             var parent = $element.parent();
//             if ($attributes.url) {
//               var html = $element.parent().html();
//               var wrapper = angular.element('<a>');
//               wrapper.attr('href', $attributes.url);
//               parent.empty().append(wrapper);
//               wrapper.html(html);
//             }
//           }
//         };
//       }
//     };
//   });
