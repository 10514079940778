angular
  .module('app')
  .component('dsHeader', {
    templateUrl: 'app/header.html',
    controller: HeaderController
  });

/** @ngInject */
function HeaderController($http) {
  var vm = this;
  $http
    .get('app/header.json', {cache: true})
    .then(function (response) {
      vm.menubar = response.data;
    });
}
